/* eslint-disable */
import da from "date-fns/esm/locale/da/index.js";
import React, { useState, useEffect, useRef } from "react";
import ProfileService from "../../api/services/profileService";
import { FaExclamationCircle, FaCoins, FaDonate, FaCopy } from "react-icons/fa";
import { useUserContext } from "../../Providers/UserProvider";
import SurebetService from "../../api/services/surebetService";
import { toast, ToastContainer } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";

const tooltipR = (
    <Tooltip id="tooltipR">
        <strong>Reportar</strong>
    </Tooltip>
);
const tooltipAP = (
    <Tooltip id="tooltipAP">
        <strong>Agregar Apuesta</strong>
    </Tooltip>
);
const tooltipAS = (
    <Tooltip id="tooltipAS">
        <strong>Agregar Surebet</strong>
    </Tooltip>
);

// Función principal
const Calculadora = ({ actSurebet, tc, stake, userTc, type }) => {
    //USE FORM
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    // Inicializacion de variables y datos de scaner
    const refreshToken = localStorage.getItem("refreshToken");
    const { user, filtrosLive, filtrosPrematch } = useUserContext();
    const inputRef = useRef(null);
    const [percent, setPercent] = useState(actSurebet.length > 0 ? actSurebet[0].arbprofit : 0);
    const [bankinicial, setBankinicial] = useState(stake);
    const [isTC1, setIsTC1] = useState(false);
    const [isTC2, setIsTC2] = useState(false);
    const [smsCopy, setSmsCopy] = useState("Copiar");
    const [apuestaSelect, setApuestaSelect] = useState(null);
    const [datCal, setDatCal] = useState({
        odd1: null,
        odd2: null,
        apuesta1: null,
        apuesta2: null,
        gana1: null,
        gana2: null,
    });
    const [cambio, setCambio] = useState({
        tc1: null,
        tc2: null,
    });
    const [checkTC, setCheckTC] = useState({
        tc1: true,
        tc2: true,
    });
    const [checkStake, setCheckStake] = useState({
        stake1: false,
        stake2: false,
    });
    const [userData, setuserData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("userCca");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loadingM, setLoadingM] = useState(false);
    const calDatos = calculateDatos(actSurebet, stake, setBankinicial, setDatCal);
    // Inicializacion de datos en el mounted
    const tooltipCopy = (
        <Tooltip id="tooltipCopy">
            <strong>{smsCopy}</strong>
        </Tooltip>
    );
    useEffect(() => {
        setBankinicial(stake);
    }, []);
    // SI hay data
    useEffect(() => {   
        tc_moneda(checkStake);
        if (actSurebet) {
            calDatos();
        }
        var ele = document.getElementsByName("optionStake");
        for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked) {
                ele[i].checked = false;
            }
        }
        setApuestaSelect(null);
    }, [actSurebet, tc]);

    useEffect(() => {
        tc_moneda(checkStake);
    }, [userTc]);
    // Si no hay data
    if (!actSurebet) {
        return <></>;
    }
    const filterName = (name) => {
        if (type == 2) {
            if (filtrosLive.casas.length != 0) {
                const filterSurebet = filtrosLive.casas.filter((e) => e.value == name);
                if (filterSurebet.length != 0) {
                    return filterSurebet[0].casa;
                } else {
                    return false;
                }
            }
        } else {
            if (filtrosPrematch.casas.length != 0) {
                const filterSurebet = filtrosPrematch.casas.filter((e) => e.value == name);
                if (filterSurebet.length != 0) {
                    return filterSurebet[0].casa;
                } else {
                    return false;
                }
            }
        }
    };
    //TC
    function tc_moneda(checkStake) {
        if (userData) {
            let tipoCambio1 = null;
            let tipoCambio2 = null;
            if (tc == true) {
                tipoCambio1 = (userTc * datCal.apuesta1).toFixed(2);
                tipoCambio2 = (userTc * datCal.apuesta2).toFixed(2);
                // if (checkStake != undefined) {
                //     if (!checkStake.stake1 && !checkStake.stake2) {
                //         setCambio({ tc1: Number(tipoCambio1), tc2: Number(tipoCambio2) });
                //     }
                //     if (checkStake.stake1) {
                //         setCambio((prevState) => ({ ...prevState, tc2: Number(tipoCambio2) }));
                //     }
                //     if (checkStake.stake2) {
                //         setCambio((prevState) => ({ ...prevState, tc1: Number(tipoCambio1) }));
                //     }
                // } else {
                //     setCambio((previousState) => ({
                //         ...previousState,
                //         tc1: Number(tipoCambio1),
                //         tc2: Number(tipoCambio2),
                //     }));
                // }
            }
        }
    }

    // Función de Modificar cuotas y variables
    const handleChangeValuesCal = (e) => {
        const { name, value } = e.target;
        if (name === "odd1") {
            calCuota1(value, datCal, bankinicial, checkStake, setBankinicial);
        } else if (name === "odd2") {
            calCuota2(datCal, value, bankinicial, setDatCal, checkStake, setBankinicial);
        } else if (name === "apuesta1") {
            calApuesta1(datCal, value, setBankinicial, setDatCal, false);
        } else if (name === "apuesta2") {
            calApuesta2(datCal, value, setBankinicial, setDatCal, false);
        } else if (name === "bankinicial") {
            setBankinicial(value);
            calCapital(value, actSurebet, setDatCal, setBankinicial);
        } else if (name === "tc1") {
            calTC1(datCal, value, setBankinicial, setDatCal);
        } else if (name === "tc2") {
            calTC2(datCal, value, setBankinicial, setDatCal);
        }
    };

    //Funciones generales
    //CUOTAS: modifican stake y ganancia
    function calCuota1(value, datCal, bankinicial, checkStake, setBankinicial) {
        let od1 = parseFloat(value);
        let od2 = parseFloat(datCal.odd2);
        let indice = 1 / od1 + 1 / od2;
        let tipoCambio1 = null;
        let tipoCambio2 = null;
        if (checkStake.stake2 && !checkStake.stake1) {
            let apuesta2 = parseFloat(datCal.apuesta2);
            const tot = parseFloat(apuesta2 * od2).toFixed(2);
            let ap1 = value != "" ? parseFloat(tot / od1).toFixed(2) : 0;
            let total = parseFloat(parseFloat(ap1) + parseFloat(apuesta2)).toFixed(2);
            let gana1 = value != "" ? parseFloat(ap1 * od1 - total).toFixed(2) : 0;
            let gana2 = value != "" ? parseFloat(apuesta2 * od2 - total).toFixed(2) : 0;
            setDatCal({ ...datCal, odd1: value, apuesta1: isNaN(ap1) ? (0).toFixed(2) : ap1, gana1: gana1, gana2: gana2 });
            tipoCambio1 = isNaN(ap1) ? (0).toFixed(2) : (userTc * ap1).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc1: tipoCambio1,
            }));
            let porcentaje = (parseFloat(gana1 / total) * 100).toFixed(2);
            setPercent(porcentaje);
            setBankinicial(total);
            setDatosBankinicial(total);
        }
        if (checkStake.stake1 && !checkStake.stake2) {
            let apuesta1 = parseFloat(datCal.apuesta1);
            const tot = parseFloat(apuesta1 * od1).toFixed(2);
            let apuesta2 = value != "" ? parseFloat(tot / od2).toFixed(2) : 0;
            let total = parseFloat(parseFloat(apuesta2) + parseFloat(apuesta1)).toFixed(2);
            let gana1 = value != "" ? parseFloat(apuesta2 * od2 - total).toFixed(2) : 0;
            let gana2 = value != "" ? parseFloat(apuesta1 * od1 - total).toFixed(2) : 0;
            setDatCal({ ...datCal, odd1: value, apuesta2: isNaN(apuesta2) ? (0).toFixed(2) : apuesta2, gana1: gana1, gana2: gana2 });
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc2: tipoCambio2,
            }));
            let porcentaje = (parseFloat(gana1 / total) * 100).toFixed(2);
            setPercent(porcentaje);
            setBankinicial(total);
            setDatosBankinicial(total);
        }
        if (!checkStake.stake1 && !checkStake.stake2) {
            let ap1 = value != "" ? parseFloat(bankinicial / (od1 * indice)).toFixed(2) : parseFloat(0).toFixed(2);
            let apuesta2 = parseFloat(bankinicial / (od2 * indice)).toFixed(2);
            let gana1 = value != "" ? parseFloat(ap1 * od1 - bankinicial).toFixed(2) : 0;
            let gana2 = value != "" ? parseFloat(apuesta2 * od2 - bankinicial).toFixed(2) : 0;
            setDatCal({
                ...datCal,
                odd1: value,
                apuesta1: isNaN(ap1) ? (0).toFixed(2) : ap1,
                apuesta2: isNaN(apuesta2) ? (0).toFixed(2) : apuesta2,
                gana1: gana1,
                gana2: gana2,
            });
            tipoCambio1 = isNaN(ap1) ? (0).toFixed(2) : (userTc * ap1).toFixed(2);
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            // console.log("tipoCambio1", tipoCambio1);
            // console.log("tipoCambio2", tipoCambio2);
            let porcentaje = (parseFloat(gana1 / bankinicial) * 100).toFixed(2);
            setPercent(porcentaje);
            setCambio({ tc1: tipoCambio1, tc2: tipoCambio2 });
        }
    }
    function calCuota2(datCal, value, bankinicial, setDatCal, checkStake, setBankinicial) {
        // console.log("cuota2");
        const odd1 = parseFloat(datCal.odd1);
        const odd2 = parseFloat(value);
        const indice = 1 / odd1 + 1 / odd2;
        let tipoCambio1 = null;
        let tipoCambio2 = null;
        if (checkStake.stake1 && !checkStake.stake2) {
            const apuesta1 = parseFloat(datCal.apuesta1);
            const tot = parseFloat(apuesta1 * odd1).toFixed(2);
            const apuesta2 = value != "" ? parseFloat(tot / odd2).toFixed(2) : parseFloat(0).toFixed(2);
            let total = parseFloat(parseFloat(apuesta1) + parseFloat(apuesta2)).toFixed(2);
            const gana1 = value != "" ? parseFloat(apuesta1 * odd1 - total).toFixed(2) : 0;
            const gana2 = value != "" ? parseFloat(apuesta2 * odd2 - total).toFixed(2) : 0;
            setDatCal({ ...datCal, odd2: value, apuesta2: isNaN(apuesta2) ? (0).toFixed(2) : apuesta2, gana1: gana1, gana2: gana2 });
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc2: tipoCambio2,
            }));
            let porcentaje = (parseFloat(gana1 / total) * 100).toFixed(2);
            setPercent(porcentaje);
            setBankinicial(total);
            setDatosBankinicial(total);
        }
        if (checkStake.stake2 && !checkStake.stake1) {
            const apuesta2 = parseFloat(datCal.apuesta2);
            const tot = parseFloat(apuesta2 * odd2).toFixed(2);
            const apuesta1 = value != "" ? parseFloat(tot / odd1).toFixed(2) : parseFloat(0).toFixed(2);
            let total = parseFloat(parseFloat(apuesta2) + parseFloat(apuesta1)).toFixed(2);
            const gana1 = value != "" ? parseFloat(apuesta2 * odd2 - total).toFixed(2) : 0;
            const gana2 = value != "" ? parseFloat(apuesta1 * odd1 - total).toFixed(2) : 0;
            setDatCal({ ...datCal, odd2: value, apuesta1: isNaN(apuesta1) ? (0).toFixed(2) : apuesta1, gana1: gana1, gana2: gana2 });
            tipoCambio1 = isNaN(apuesta1) ? (0).toFixed(2) : (userTc * apuesta1).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc1: tipoCambio1,
            }));
            let porcentaje = (parseFloat(gana1 / total) * 100).toFixed(2);
            setPercent(porcentaje);
            setBankinicial(total);
            setDatosBankinicial(total);
        }
        if (!checkStake.stake2 && !checkStake.stake1) {
            const apuesta1 = parseFloat(bankinicial / (odd1 * indice)).toFixed(2);
            const apuesta2 = value != "" ? parseFloat(bankinicial / (odd2 * indice)).toFixed(2) : parseFloat(0).toFixed(2);
            const gana1 = value != "" ? parseFloat(apuesta1 * odd1 - bankinicial).toFixed(2) : 0;
            const gana2 = value != "" ? parseFloat(apuesta2 * odd2 - bankinicial).toFixed(2) : 0;
            setDatCal({
                ...datCal,
                odd2: value,
                apuesta1: isNaN(apuesta1) ? (0).toFixed(2) : apuesta1,
                apuesta2: isNaN(apuesta2) ? (0).toFixed(2) : apuesta2,
                gana1: gana1,
                gana2: gana2,
            });
            tipoCambio1 = isNaN(apuesta1) ? (0).toFixed(2) : (userTc * apuesta1).toFixed(2);
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            let porcentaje = (parseFloat(gana1 / bankinicial) * 100).toFixed(2);
            setPercent(porcentaje);
            setCambio({ tc1: tipoCambio1, tc2: tipoCambio2 });
        }
    }
    //CHANGE TC

    //STAKE: modifca el otro stake y ganancias
    function calApuesta1(datCal, value, setBankinicial, setDatCal, val) {
        // console.log("no creo");
        let tipoCambio1 = null;
        let tipoCambio2 = null;
        const odd1 = datCal.odd1;
        const odd2 = datCal.odd2;
        const apuesta1 = Number(value);
        const total1 = Number((apuesta1 * odd1).toFixed(2));
        const apuesta2 = Number((total1 / odd2).toFixed(2));
        const montoTotal = (apuesta1 + apuesta2).toFixed(2);
        const gana1 = Number((total1 - montoTotal).toFixed(2));
        const total2 = Number((apuesta2 * odd2).toFixed(2));
        const gana2 = Number((total2 - montoTotal).toFixed(2));
        if (checkTC.tc1 && val) {
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc2: tipoCambio2,
            }));
        } else {
            tipoCambio1 = isNaN(apuesta1) ? (0).toFixed(2) : (userTc * apuesta1).toFixed(2);
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            setCambio({ tc1: tipoCambio1, tc2: tipoCambio2 });
        }
        setBankinicial(montoTotal);
        setDatosBankinicial(montoTotal);
        setDatCal({
            ...datCal,
            apuesta1: value,
            apuesta2: apuesta2,
            gana1: gana1,
            gana2: gana2,
        });
        // setPercent(((gana1 * 100) / montoTotal).toFixed(2));
    }
    function calApuesta2(datCal, value, setBankinicial, setDatCal, val) {
        let tipoCambio1 = null;
        let tipoCambio2 = null;
        const odd1 = datCal.odd1;
        const odd2 = datCal.odd2;
        const apuesta2 = Number(value);
        const total2 = Number((apuesta2 * odd2).toFixed(2));
        const apuesta1 = Number((total2 / odd1).toFixed(2));
        const montoTotal = Number((apuesta1 + apuesta2).toFixed(2));
        const gana2 = Number((total2 - montoTotal).toFixed(2));
        const total1 = Number((apuesta1 * odd1).toFixed(2));
        const gana1 = Number((total1 - montoTotal).toFixed(2));
        if (checkTC.tc2 && val) {
            tipoCambio1 = isNaN(apuesta1) ? (0).toFixed(2) : (userTc * apuesta1).toFixed(2);
            setCambio((existingValues) => ({
                ...existingValues,
                tc1: tipoCambio1,
            }));
        } else {
            tipoCambio1 = isNaN(apuesta1) ? (0).toFixed(2) : (userTc * apuesta1).toFixed(2);
            tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
            setCambio({ tc1: tipoCambio1, tc2: tipoCambio2 });
        }
        setBankinicial(montoTotal);
        setDatosBankinicial(montoTotal);
        setDatCal({ ...datCal, apuesta1: apuesta1, apuesta2: value, gana1: gana1, gana2: gana2 });
        // setPercent(((gana1 * 100) / montoTotal).toFixed(2));
    }
    //TC: MODIFICA TODO
    function calTC1(datCal, value, setBankinicial, setDatCal) {
        setCambio((existingValues) => ({
            ...existingValues,
            tc1: value,
        }));
        let val = parseFloat(value / userTc).toFixed(2);
        calApuesta1(datCal, val, setBankinicial, setDatCal, true);
    }
    function calTC2(datCal, value, setBankinicial, setDatCal) {
        setCambio((existingValues) => ({
            ...existingValues,
            tc2: value,
        }));
        let val = parseFloat(value / userTc).toFixed(2);
        calApuesta2(datCal, val, setBankinicial, setDatCal, true);
    }
    //MODIFICAN EL TOTAL
    function calCapital(value, actSurebet, setDatCal, setBankinicial) {
        let tipoCambio1 = null;
        let tipoCambio2 = null;
        const bankinicial = parseFloat(value);
        const odd1 = parseFloat(datCal.odd1);
        const odd2 = parseFloat(datCal.odd2);
        const indice = parseFloat(1 / odd1 + 1 / odd2);
        const apuesta1 = (bankinicial / (odd1 * indice)).toFixed(2);
        const apuesta2 = (bankinicial / (odd2 * indice)).toFixed(2);
        const gana1 = (apuesta1 * odd1 - bankinicial).toFixed(2);
        const gana2 = (apuesta2 * odd2 - bankinicial).toFixed(2);
        setDatCal((previousState) => ({
            ...previousState,
            apuesta1: apuesta1,
            apuesta2: apuesta2,
            gana1: gana1,
            gana2: gana2,
        }));
        tipoCambio1 = isNaN(apuesta1) ? (0).toFixed(2) : (userTc * apuesta1).toFixed(2);
        tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
        setCambio({ tc1: tipoCambio1, tc2: tipoCambio2 });
        setBankinicial(value);
    }

    function calculateDatos(actSurebet, stake, setBankinicial, setDatCal) {
        return () => {
            if (actSurebet.length > 0) {
                const odd1 = Number(actSurebet[0].odd);
                const odd2 = Number(actSurebet[1].odd);
                const indice = Number((1 / odd1 + 1 / odd2).toFixed(2));
                const apuesta1 = Number((stake / (odd1 * indice)).toFixed(2));
                const apuesta2 = Number((stake / (odd2 * indice)).toFixed(2));
                const gana1 = Number((apuesta1 * actSurebet[0].odd - stake).toFixed(2));
                const gana2 = Number((apuesta2 * actSurebet[1].odd - stake).toFixed(2));
                setDatCal((previousState) => ({
                    ...previousState,
                    odd1: odd1,
                    odd2: odd2,
                    apuesta1: apuesta1,
                    apuesta2: apuesta2,
                    gana1: gana1,
                    gana2: gana2,
                }));
                setPercent(actSurebet[0].arbprofit);
                setBankinicial(stake)
                let tipoCambio1 = isNaN(apuesta1) ? (0).toFixed(2) : (userTc * apuesta1).toFixed(2);
                let tipoCambio2 = isNaN(apuesta2) ? (0).toFixed(2) : (userTc * apuesta2).toFixed(2);
                setCambio({ tc1: tipoCambio1, tc2: tipoCambio2 });
            }
        };
    }
    function setDatosBankinicial(value) {
        setBankinicial(value);
    }
    const handleBlur = () => {
        inputRef.current.focus();
    };

    const addApuesta = async () => {
        setLoading(true);
        const pathname = window.location.pathname;
        let checkBet = document.getElementsByName('optionStake')
        if (checkBet != null) {
            if (!checkBet[0].checked && !checkBet[1].checked) {
                toast.warning("Debe seleccionar una apuesta primero", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "B",
                });
            }
            if (checkBet[0].checked && !checkBet[1].checked) {
                const body = {
                    casa: actSurebet[0].bookmaker,
                    cuota: parseFloat(actSurebet[0].odd),
                    deporte: actSurebet[0].sportname,
                    evento: actSurebet[0].eventname,
                    fechaevento: actSurebet[0].eventstarttime,
                    mercado: actSurebet[0].arboutcome,
                    porcentaje: parseFloat(actSurebet[0].arbprofit),
                    refreshToken: "Bearer " + refreshToken,
                    stake: datCal.apuesta1,
                };
                // console.log('body 1', body)
                if (pathname == "/live") {
                    body.tipo = 1;
                } else {
                    body.tipo = 2;
                }
                const respBet = await SurebetService.addBets(body);
                // console.log('respBet', respBet)
                if (respBet.status) {
                    toast.success("Apuesta Agregada !", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "B",
                    });
                } else {
                    toast.error("Error al agregar la apuesta", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "B",
                    });
                }
            }
            if (checkBet[1].checked && !checkBet[0].checked) {
                const body = {
                    casa: actSurebet[1].bookmaker,
                    cuota: parseFloat(actSurebet[1].odd),
                    deporte: actSurebet[1].sportname,
                    evento: actSurebet[1].eventname,
                    fechaevento: actSurebet[1].eventstarttime,
                    mercado: actSurebet[1].arboutcome,
                    porcentaje: parseFloat(actSurebet[1].arbprofit),
                    refreshToken: "Bearer " + refreshToken,
                    stake: datCal.apuesta2,
                };
                // console.log('body 2', body)
                if (pathname == "/live") {
                    body.tipo = 1;
                } else {
                    body.tipo = 2;
                }
                const respBet = await SurebetService.addBets(body);
                // console.log('respBet', respBet)
                if (respBet.status) {
                    toast.success("Apuesta Agregada !", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "B",
                    });
                } else {
                    toast.error("Error al agregar la apuesta", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "B",
                    });
                }
            }
        }
        setLoading(false);
    };
    const addSureBet = async () => {
        setLoading2(true);
        const pathname = window.location.pathname;
        const body = {
            deporte: actSurebet[0].sportname,
            evento: actSurebet[0].eventname,
            tipo: pathname == "/live" ? 1 : 2,
            casa1: actSurebet[0].bookmaker,
            casa2: actSurebet[1].bookmaker,
            mercado1: actSurebet[0].arboutcome,
            mercado2: actSurebet[1].arboutcome,
            cuota1: parseFloat(datCal.odd1),
            cuota2: parseFloat(datCal.odd2),
            stake1: parseFloat(datCal.apuesta1),
            stake2: parseFloat(datCal.apuesta2),
            yield1: parseFloat(datCal.gana1),
            yield2: parseFloat(datCal.gana2),
            profit: actSurebet[0].arbprofit,
            total: parseFloat(bankinicial),
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log(actSurebet);
        const respSureBet = await SurebetService.addSureBets(body);
        if (respSureBet.status) {
            toast.success("SureBet Agregada !", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "A",
            });
        } else {
            toast.error("Error al agregar la surebet", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "A",
            });
        }
        setLoading2(false);
    };
    const resetForm = () => {
        reset({
            observacion: "",
        });
    };
    const openModal = () => {
        $("#modal_obs_report").modal("show");
    };
    const filterTime = (time) => {
        let color = "pink";
        let tiempo = time.substring(time.length, 2).trim();
        let numero = time.substring(0, time.indexOf(" "));
        numero = parseInt(numero);
        if (tiempo == "few") {
            color = "yellow";
        }
        if (tiempo == "sec") {
            if (numero >= 1 && numero <= 30) {
                color = "yellow";
            }
            if (numero >= 31 && numero <= 59) {
                color = "blue";
            }
        }
        return color;
    };
    const onSubmit = async (data) => {
        setLoadingM(true);
        const pathname = window.location.pathname;
        const body = {
            tipo: pathname == "/live" ? 1 : 2,
            profit: actSurebet[0].arbprofit,
            deporte: actSurebet[0].sportname,
            evento: actSurebet[0].eventname,
            casa1: actSurebet[0].bookmaker,
            casa2: actSurebet[1].bookmaker,
            mercado1: actSurebet[0].arboutcome,
            mercado2: actSurebet[1].arboutcome,
            observacion: data.observacion,
            refreshToken: "Bearer " + refreshToken,
        };
        const respReport = await ProfileService.reportSureBet(body);
        if (respReport.status) {
            Swal.fire({
                title: "Reportado!",
                icon: "success",
                text: "El reporte ha sido enviado con éxito.",
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al enviar el reporte.",
            });
        }
        resetForm();
        $("#modal_obs_report").modal("hide");
        setLoadingM(false);
    };
    const copyLeague = (num) => {
        var nameCopy = [];
        var aux = document.createElement("input");
        var val = document.getElementById("name_league1").innerHTML;
        const type1 = val.indexOf("⇄") != -1 ? true : false;
        const type2 = val.indexOf("-") != -1 ? true : false;
        if (type1) {
            nameCopy = val.split("⇄");
        }
        if (type2) {
            nameCopy = val.split("-");
        }
        if (nameCopy.length > 0) {
            if (num == 1) {
                aux.setAttribute("value", nameCopy[0].trim());
                document.body.appendChild(aux);
                aux.select();
                document.execCommand("copy");
                document.body.removeChild(aux);
                setSmsCopy("¡Copiado!");
                setTimeout(() => {
                    setSmsCopy("Copiar");
                }, "2000");
            }
            if (num == 2) {
                aux.setAttribute("value", nameCopy[1].trim());
                document.body.appendChild(aux);
                aux.select();
                document.execCommand("copy");
                document.body.removeChild(aux);
                setSmsCopy("¡Copiado!");
                setTimeout(() => {
                    setSmsCopy("Copiar");
                }, "2000");
            }
        }
    };
    const onlyNumber = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    //Diseño general
    return (
        <div className="w-100 rounded-bottom shadow">
            <div className="tablero_info rounded-bottom d-flex flex-column">
                <table className="w-100 rounded-bottom">
                    <tbody className="rounded-bottom">
                        <tr className="d-none d-lg-table-row">
                            <td
                                align="center"
                                colSpan={1}
                                className={`info_percent text-truncate bg_surebet-${actSurebet.length > 0 ? filterTime(actSurebet[0].arbgenerationtime) : "red"
                                    }  text-black fw-normal fs-14 py-1`}
                            >
                                {percent}%
                            </td>
                            <td
                                align="center"
                                colSpan={1}
                                className="info_deporte text-truncate  bg_surebet_gray text-white fw-normal fs-14 py-1"
                            >
                                {actSurebet.length > 0 ? actSurebet[0].sportname : ""}
                            </td>
                            {/* {type == 1 && <td align="center" colSpan={1} className="info_deporte  bg_surebet_score text-white fw-normal fs-14 py-1">{actSurebet.length > 0 ?actSurebet[0].eventstarttime : ""}</td>}
                            {type == 2 && <td align="center" colSpan={1} className="info_deporte  bg_surebet_score text-white fw-normal fs-14 py-1">{actSurebet.length > 0 ?actSurebet[0].currentscore : ""}</td>} */}
                            <td align="center" colSpan={2} className="info_time text-white fw-normal text-truncate fs-14 py-1  bg_surebet_score">
                                {actSurebet.length > 0 ? actSurebet[0].fulltime : ""}
                            </td>
                            <td
                                align="center"
                                colSpan={tc == true ? 3 : 2}
                                className="info_time  py-1 text-black text-truncate fw-normal fs-7 bg-gray_light"
                                style={{ maxWidth: "150px" }}
                            >
                                {actSurebet.length > 0 ? actSurebet[0].leaguename : ""}
                            </td>
                        </tr>
                        <tr className="d-table-row d-lg-none">
                            <td
                                align="center"
                                colSpan={1}
                                className={`info_percent text-truncate bg_surebet-${actSurebet.length > 0 ? filterTime(actSurebet[0].arbgenerationtime) : "red"
                                    }  text-black fw-normal fs-14 py-1`}
                            >
                                {percent}%
                            </td>
                            <td
                                align="center"
                                colSpan={2}
                                className="info_deporte text-truncate  bg_surebet_gray text-white fw-normal fs-14 py-1"
                            >
                                {actSurebet.length > 0 ? actSurebet[0].sportname : ""}
                            </td>
                            {/* {type == 1 && <td align="center" colSpan={1} className="info_deporte  bg_surebet_score text-white fw-normal fs-14 py-1">{actSurebet.length > 0 ?actSurebet[0].eventstarttime : ""}</td>}
                            {type == 2 && <td align="center" colSpan={1} className="info_deporte  bg_surebet_score text-white fw-normal fs-14 py-1">{actSurebet.length > 0 ?actSurebet[0].currentscore : ""}</td>} */}
                            <td align="center" colSpan={tc == true ? 4 : 3} className="info_time text-white fw-normal text-truncate fs-14 py-1  bg_surebet_score">
                                {actSurebet.length > 0 ? actSurebet[0].fulltime : ""}
                            </td>
                        </tr>
                        <tr>
                            <td
                                align="center"
                                className="info_evento header_event fw-normal fs-12 text-white py-1"
                                colSpan={tc == true ? 7 : 6}
                            >
                                <div className="w-100 d-flex justify-content-center align-items-center">
                                    <OverlayTrigger placement="top" overlay={tooltipCopy}>
                                        <span className="cursor-pointer " onClick={() => copyLeague(1)}>
                                            <FaCopy className="ms-2"></FaCopy>
                                        </span>
                                    </OverlayTrigger>
                                    <div id="name_league1" className="fw-normal fs-14 mx-1">
                                        {actSurebet.length > 0 ? actSurebet[0].eventname : ""}
                                    </div>
                                    <OverlayTrigger placement="top" overlay={tooltipCopy}>
                                        <span className="cursor-pointer " onClick={() => copyLeague(2)}>
                                            <FaCopy className=""></FaCopy>
                                        </span>
                                    </OverlayTrigger>
                                </div>

                                <div className="d-flex d-lg-none justify-content-center align-items-center fw-semibold fs-14">{actSurebet.length > 0 ? actSurebet[0].leaguename : ""}</div>
                            </td>
                        </tr>

                        {/* Headers */}
                        <tr className="d-none d-lg-table-row">
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase"></td>
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Casa
                            </td>
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Mercado
                            </td>
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Cuota
                            </td>
                            {tc == true && (
                                <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                    TC
                                </td>
                            )}
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Stake
                            </td>
                            <td align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Ganancia
                            </td>
                        </tr>
                        <tr className="d-table-row d-lg-none">
                            <td colSpan={1} align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Casa
                            </td>
                            <td colSpan={1} align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Mercado
                            </td>
                            <td colSpan={2} align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Cuota
                            </td>
                            <td colSpan={tc == true ? 3 : 2} align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Stake
                            </td>
                        </tr>
                        {/* Headers */}

                        {/* First Evento */}
                        <tr className="d-none d-lg-table-row content">
                            <td align="center" className="fw-normal box_radio">
                                <input
                                    className="form-check-input h-20px w-20px"
                                    type="radio"
                                    value=""
                                    id="flexRadioDefault1"
                                    aria-label="flexRadioDefault1"
                                    name="optionStake"
                                // onChange={() => selectApuesta(actSurebet[0], datCal, 1)}
                                />
                            </td>
                            <td align="center" className="fw-normal text-dark">
                                {actSurebet.length > 0
                                    ? actSurebet[0].bookmakers
                                    : ""}
                            </td>
                            <td align="center" className="fw-normal text-dark">
                                {actSurebet.length > 0 ? actSurebet[0].arboutcome : ""}
                            </td>
                            <td align="center" className="fw-normal text-dark">
                                <div className="px-lg-5 py-2 w-100 text-center d-flex align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        name="odd1"
                                        aria-label="odd1"
                                        value={datCal.odd1 || ""}
                                        autoComplete="off"
                                        readOnly={checkStake.stake1 && checkStake.stake2}
                                        onChange={handleChangeValuesCal}
                                    />
                                </div>
                            </td>
                            {tc == true && (
                                <td
                                    align="center"
                                    className="fw-normal text-dark py-2 text-center d-flex gap-2 align-content-center justify-content-center"
                                >
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="tc1"
                                        aria-label="tc1"
                                        readOnly={!checkTC.tc1 || checkStake.stake1}
                                        value={cambio.tc1 || ""}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="form-check form-switch form-check-custom form-check-success form-check-solid">
                                        <input
                                            className="form-check-input ms-1 cursor-pointer"
                                            type="checkbox"
                                            value="checktc1"
                                            name="checktc1"
                                            aria-label="checktc1"
                                            checked={checkTC.tc1 && !checkStake.stake1}
                                            onChange={(e) => {
                                                setCheckTC((previousState) => ({
                                                    ...previousState,
                                                    tc1: e.target.checked,
                                                }));
                                            }}
                                        />
                                    </label>
                                </td>
                            )}
                            <td align="center" className="fw-normal">
                                <div className="px-2 px-lg-5 py-2 w-100 text-center d-flex align-content-center justify-content-center gap-3">
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="apuesta1"
                                        aria-label="apuesta1"
                                        value={datCal.apuesta1 || ""}
                                        // readOnly={checkStake.stake1}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="custom-radio-checkbox">
                                        <input
                                            className="custom-radio-checkbox__input"
                                            type="checkbox"
                                            name="stake1"
                                            value="stake1"
                                            onChange={(e) => {
                                                setCheckStake((previousState) => ({
                                                    ...previousState,
                                                    stake1: e.target.checked,
                                                }));
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                            <td align="center" className="fw-normal text-dark">
                                {datCal.gana1}
                            </td>
                        </tr>
                        <tr className="d-table-row d-lg-none content">
                            <td colSpan={1} align="center" className="fw-normal text-dark">
                                {actSurebet.length > 0
                                    ? actSurebet[0].bookmakers
                                        : ""}
                            </td>
                            <td colSpan={1} align="center" className="fw-normal text-dark">
                                {actSurebet.length > 0 ? actSurebet[0].arboutcome : ""}
                            </td>
                            <td colSpan={2} align="center" className="fw-normal text-dark">
                                <div className="px-lg-5 py-2 w-100 text-center d-flex align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        name="odd1"
                                        aria-label="odd1"
                                        value={datCal.odd1 || ""}
                                        autoComplete="off"
                                        readOnly={checkStake.stake1 && checkStake.stake2}
                                        onChange={handleChangeValuesCal}
                                    />
                                </div>
                            </td>
                            <td colSpan={tc == true ? 3 : 2} align="center" className="fw-normal">
                                <div className="px-2 px-lg-5 py-2 w-100 text-center d-flex align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota me-1"
                                        type="text"
                                        autoComplete="off"
                                        name="apuesta1"
                                        value={datCal.apuesta1 || ""}
                                        readOnly={checkStake.stake1}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="custom-radio-checkbox">
                                        <input
                                            className="custom-radio-checkbox__input"
                                            type="checkbox"
                                            name="stake1"
                                            value="stake1"
                                            aria-label="stake1"
                                            onChange={(e) => {
                                                setCheckStake((previousState) => ({
                                                    ...previousState,
                                                    stake1: e.target.checked,
                                                }));
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                        </tr>

                        {/* fin First Evento */}

                        {/* Second Evento */}
                        <tr className="d-none d-lg-table-row content">
                            <td align="center" className="fw-normal box_radio ">
                                <input
                                    className="form-check-input h-20px w-20px"
                                    type="radio"
                                    value=""
                                    id="flexRadioDefault"
                                    name="optionStake"
                                    aria-label="optionStake"
                                // onChange={() => selectApuesta(actSurebet[1], datCal, 2)}
                                />
                            </td>
                            <td align="center" className="fw-normal text-dark">
                                {actSurebet.length > 0
                                    ? actSurebet[1].bookmakers
                                        : ""}
                            </td>
                            <td align="center" className="fw-normal text-dark">
                                {actSurebet.length > 0 ? actSurebet[1].arboutcome : ""}
                            </td>
                            <td align="center" className="fw-normal text-dark">
                                <div className="px-lg-5 py-2 w-100 text-center d-flex align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="odd2"
                                        aria-label="odd2"
                                        value={datCal.odd2 || ""}
                                        readOnly={checkStake.stake1 && checkStake.stake2}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                </div>
                            </td>
                            {tc == true && (
                                <td
                                    align="center"
                                    className="fw-normal text-dark py-2 text-center d-flex gap-2 align-content-center justify-content-center"
                                >
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="tc2"
                                        aria-label="tc2"
                                        value={cambio.tc2 || ""}
                                        readOnly={!checkTC.tc2 || checkStake.stake2}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="form-check form-switch form-check-custom form-check-success form-check-solid">
                                        <input
                                            className="form-check-input ms-1 cursor-pointer"
                                            type="checkbox"
                                            value="checktc2"
                                            name="checktc2"
                                            aria-label="checktc2"
                                            checked={checkTC.tc2 && !checkStake.stake2}
                                            onChange={(e) => {
                                                setCheckTC((previousState) => ({
                                                    ...previousState,
                                                    tc2: e.target.checked,
                                                }));
                                            }}
                                        />
                                    </label>
                                </td>
                            )}
                            <td align="center" className="fw-normal text-dark">
                                <div className="px-2 px-lg-5 py-2 w-100 text-center d-flex gap-2 align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota me-2"
                                        type="text"
                                        autoComplete="off"
                                        name="apuesta2"
                                        aria-label="apuesta2"
                                        value={datCal.apuesta2 || ""}
                                        // readOnly={checkStake.stake2}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="custom-radio-checkbox">
                                        <input
                                            className="custom-radio-checkbox__input"
                                            type="checkbox"
                                            name="stake2"
                                            value="stake2"
                                            aria-label="stake2"
                                            onChange={(e) => {
                                                setCheckStake((previousState) => ({
                                                    ...previousState,
                                                    stake2: e.target.checked,
                                                }));
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                            <td align="center" className="fw-normal text-dark">
                                {datCal.gana2}
                            </td>
                        </tr>
                        <tr className="d-table-row d-lg-none content">
                            <td colSpan={1} align="center" className="fw-normal text-dark">
                                {actSurebet.length > 0
                                    ? actSurebet[1].bookmakers
                                        : ""}
                            </td>
                            <td colSpan={1} align="center" className="fw-normal text-dark">
                                {actSurebet.length > 0 ? actSurebet[1].arboutcome : ""}
                            </td>
                            <td colSpan={2} align="center" className="fw-normal text-dark">
                                <div className="px-lg-5 py-2 w-100 text-center d-flex align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="odd2"
                                        aria-label="odd2"
                                        value={datCal.odd2 || ""}
                                        readOnly={checkStake.stake1 && checkStake.stake2}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                </div>
                            </td>
                            <td colSpan={tc == true ? 3 : 2} align="center" className="fw-normal text-dark">
                                <div className="px-2 px-lg-5 py-2 w-100 text-center d-flex align-content-center justify-content-center">
                                    <input
                                        className="rounded inputCuota me-1"
                                        type="text"
                                        autoComplete="off"
                                        name="apuesta2"
                                        aria-label="apuesta2"
                                        value={datCal.apuesta2 || ""}
                                        readOnly={checkStake.stake2}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="custom-radio-checkbox">
                                        <input
                                            className="custom-radio-checkbox__input"
                                            type="checkbox"
                                            name="stake2"
                                            value="stake2"
                                            aria-label="stake2"
                                            onChange={(e) => {
                                                setCheckStake((previousState) => ({
                                                    ...previousState,
                                                    stake2: e.target.checked,
                                                }));
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        {/* fin Second Evento */}

                        {/* FILAS RESPONSIVAS */}
                        <tr className="d-table-row d-lg-none">
                            <td colSpan={2} align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Acciones
                            </td>
                            {tc == true && (
                                <td colSpan={1} align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                    TC
                                </td>
                            )}
                            <td colSpan={4} align="center" className="fw-normal fs-12 text-white py-1 bg_surebet_gray text-uppercase">
                                Ganancia
                            </td>
                        </tr>
                        <tr className="d-table-row d-lg-none content">
                            <td colSpan={2} align="center" className="fw-normal box_radio py-1">
                                <input
                                    className="form-check-input h-20px w-20px"
                                    type="radio"
                                    value=""
                                    id="flexRadioDefault"
                                    name="optionStake"
                                    aria-label="optionStake"
                                // onChange={() => selectApuesta(actSurebet[1], datCal, 2)}
                                />
                            </td>
                            {tc == true && (
                                <td
                                    colSpan={1}
                                    align="center"
                                    className="fw-normal text-dark py-2 text-center d-flex gap-2 align-content-center justify-content-center"
                                >
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="tc1"
                                        aria-label="tc1"
                                        readOnly={!checkTC.tc1 || checkStake.stake1}
                                        value={cambio.tc1 || ""}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="form-check form-switch form-check-custom form-check-success form-check-solid">
                                        <input
                                            className="form-check-input ms-1 cursor-pointer"
                                            type="checkbox"
                                            value="checktc1"
                                            name="checktc1"
                                            aria-label="checktc1"
                                            checked={checkTC.tc1 && !checkStake.stake1}
                                            onChange={(e) => {
                                                setCheckTC((previousState) => ({
                                                    ...previousState,
                                                    tc1: e.target.checked,
                                                }));
                                            }}
                                        />
                                    </label>
                                </td>
                            )}
                            <td colSpan={4} align="center" className="fw-normal text-dark">
                                {datCal.gana1}
                            </td>
                        </tr>
                        <tr className="d-table-row d-lg-none content">
                            <td colSpan={2} align="center" className="fw-normal box_radio py-1">
                                <input
                                    className="form-check-input h-20px w-20px"
                                    type="radio"
                                    value=""
                                    id="flexRadioDefault"
                                    name="optionStake"
                                    aria-label="optionStake"
                                // onChange={() => selectApuesta(actSurebet[1], datCal, 2)}
                                />
                            </td>
                            {tc == true && (
                                <td
                                    colSpan={1}
                                    align="center"
                                    className="fw-normal text-dark py-2 text-center d-flex gap-2 align-content-center justify-content-center"
                                >
                                    <input
                                        className="rounded inputCuota"
                                        type="text"
                                        autoComplete="off"
                                        name="tc2"
                                        aria-label="tc2"
                                        value={cambio.tc2 || ""}
                                        readOnly={!checkTC.tc2 || checkStake.stake2}
                                        onChange={(e) => {
                                            handleChangeValuesCal(e);
                                        }}
                                    />
                                    <label className="form-check form-switch form-check-custom form-check-success form-check-solid">
                                        <input
                                            className="form-check-input ms-1 cursor-pointer"
                                            type="checkbox"
                                            value="checktc2"
                                            name="checktc2"
                                            aria-label="tc2"
                                            checked={checkTC.tc2 && !checkStake.stake2}
                                            onChange={(e) => {
                                                setCheckTC((previousState) => ({
                                                    ...previousState,
                                                    tc2: e.target.checked,
                                                }));
                                            }}
                                        />
                                    </label>
                                </td>
                            )}
                            <td colSpan={4} align="center" className="fw-normal text-dark">
                                {datCal.gana2}
                            </td>
                        </tr>
                        {/* ULTIMA FILA */}
                        <tr className="d-none d-lg-table-row box_total bg_surebet-gray text-white">
                            <td colSpan={3} align="center">
                                <div>
                                    <OverlayTrigger placement="top" overlay={tooltipAP}>
                                        <button onClick={addApuesta} className="btn btn-secondary btn-sm">
                                            {loading ? (
                                                <span className="indicator-label">
                                                    Agregando...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            ) : (
                                                <span className="indicator-label ">
                                                    + <FaCoins></FaCoins>{" "}
                                                </span>
                                            )}
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={tooltipAS}>
                                        <button className="btn btn-secondary btn-sm ms-2" onClick={addSureBet}>
                                            {loading2 ? (
                                                <span className="indicator-label">
                                                    Agregando...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            ) : (
                                                <span className="indicator-label ">
                                                    + <FaDonate></FaDonate>{" "}
                                                </span>
                                            )}
                                        </button>
                                    </OverlayTrigger>
                                </div>
                            </td>
                            <td colSpan={tc == true ? 2 : 1} className="text-uppercase text-center">
                                Total
                            </td>
                            <td className="text-center">
                                <input
                                    className="text-center rounded inputCuota inputCuotaTot"
                                    style={{ maxWidth: "60px" }}
                                    type="text"
                                    autoComplete="off"
                                    name="bankinicial"
                                    aria-label="bankinicial"
                                    value={bankinicial}
                                    // key={bankinicial}
                                    // ref={inputRef}
                                    // onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChangeValuesCal(e);
                                    }}
                                />
                            </td>
                            {/* <td colSpan={1} align="center">
                                <OverlayTrigger placement="top" overlay={tooltipR}>
                                    <button type="button" className="btn btn-youtube me-5 btn-sm" onClick={openModal}>
                                        <FaExclamationCircle></FaExclamationCircle>
                                    </button>
                                </OverlayTrigger>
                            </td> */}
                            <td></td>
                        </tr>
                        <tr className="d-table-row d-lg-none box_total bg_surebet-gray text-white">
                            <td colSpan={3} align="center">
                                <div>
                                    <OverlayTrigger placement="top" overlay={tooltipAP}>
                                        <button onClick={addApuesta} className="btn btn-secondary btn-sm">
                                            {loading ? (
                                                <span className="indicator-label">
                                                    Agregando...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            ) : (
                                                <span className="indicator-label ">
                                                    + <FaCoins></FaCoins>{" "}
                                                </span>
                                            )}
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={tooltipAS}>
                                        <button className="btn btn-secondary btn-sm ms-2" onClick={addSureBet}>
                                            {loading2 ? (
                                                <span className="indicator-label">
                                                    Agregando...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            ) : (
                                                <span className="indicator-label ">
                                                    + <FaDonate></FaDonate>{" "}
                                                </span>
                                            )}
                                        </button>
                                    </OverlayTrigger>
                                </div>
                            </td>
                            <td colSpan={1} className="text-uppercase text-center">
                                Total
                            </td>
                            <td colSpan={3} className="text-center">
                                <input
                                    className="text-center rounded inputCuota inputCuotaTot"
                                    style={{ maxWidth: "60px" }}
                                    type="text"
                                    autoComplete="off"
                                    name="bankinicial"
                                    aria-label="bankinicial"
                                    value={bankinicial}
                                    // key={bankinicial}
                                    // ref={inputRef}
                                    // onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChangeValuesCal(e);
                                    }}
                                />
                            </td>
                            <td></td>
                        </tr>
                        {/* ULTIMA FILA */}
                    </tbody>
                </table>
                {/* <div className="p-3 row mx-0">
                    <div className="col-8 d-flex justify-content-end">
                        
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        
                    </div>
                </div> */}
            </div>
            <div className="modal fade" id="modal_obs_report" tabIndex="-1" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <form
                            className="form fv-plugins-bootstrap5 fv-plugins-framework"
                            id="modal_obs_report_form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="modal-header" id="modal_obs_report_header">
                                <h2 className="">Reportar Surebet</h2>
                                <div
                                    id="modal_obs_report_close"
                                    className="btn btn-icon btn-sm btn-active-icon-primary"
                                    data-bs-dismiss="modal"
                                    onClick={resetForm}
                                >
                                    <span className="svg-icon svg-icon-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect
                                                opacity="0.5"
                                                x="6"
                                                y="17.3137"
                                                width="16"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-45 6 17.3137)"
                                                fill="currentColor"
                                            ></rect>
                                            <rect
                                                x="7.41422"
                                                y="6"
                                                width="16"
                                                height="2"
                                                rx="1"
                                                transform="rotate(45 7.41422 6)"
                                                fill="currentColor"
                                            ></rect>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-body py-10 px-lg-17">
                                <div
                                    className="scroll-y me-n7 pe-7"
                                    id="modal_obs_report_scroll"
                                    data-kt-scroll="true"
                                    data-kt-scroll-activate="{default: false, lg: true}"
                                    data-kt-scroll-max-height="auto"
                                    data-kt-scroll-dependencies="#modal_obs_report_header"
                                    data-kt-scroll-wrappers="#modal_obs_report_scroll"
                                    data-kt-scroll-offset="300px"
                                    style={{ maxHeight: "1035px" }}
                                >
                                    <div className="row mx-0">
                                        <div className="col-12">
                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                <span className="">Observación</span>
                                            </label>
                                            <textarea
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Observación"
                                                name="observacion"
                                                data-kt-autosize="true"
                                                rows={6}
                                                {...register("observacion")}
                                            />
                                            {errors.observacion?.type === "required" && (
                                                <p className="text-danger">El campo es requerido</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer flex-center">
                                <button
                                    type="reset"
                                    id="modal_obs_report_cancel"
                                    className="btn btn-danger me-3"
                                    data-bs-dismiss="modal"
                                    onClick={resetForm}
                                >
                                    Cerrar
                                </button>
                                <button type="submit" id="modal_obs_report_submit" className="btn btn-primary" disabled={loadingM}>
                                    {loadingM ? (
                                        <span className="">
                                            reportando....
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    ) : (
                                        <span className="indicator-label">Reportar</span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}
            <ToastContainer
                theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                className="container_surebets "
                enableMultiContainer
                containerId={"A"}
                autoClose={1000}
                position={toast.POSITION.BOTTOM_RIGHT}
            />
            <ToastContainer
                theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                className="container_bets "
                enableMultiContainer
                containerId={"B"}
                autoClose={1000}
                position={toast.POSITION.BOTTOM_RIGHT}
            />
        </div>
    );
};

export default Calculadora;
