/* eslint-disable */
import React, { useState, lazy, Suspense, useEffect, useCallback } from "react";
import "./arbitraje.css";
import { FaWindowRestore, FaCalculator, FaPause, FaPlay } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Ventana from "./Ventana";
import Main from "../../../layout/main";
import { useUserContext } from "../../../Providers/UserProvider";
import { io } from "socket.io-client";
import ValidateService from "../../../api/services/validateService";
import { el } from "date-fns/locale";
// import Chat from "../../Chat/index";
//COMPONENTES
const Filtro = lazy(() => import("../../../Components/filtro/Filtro"));
const FiltroEspecial = lazy(() => import("../../../Components/filtro/FiltroEspecial"));
const Calculadora = lazy(() => import("../../../Components/caluladora/Calculadora"));
const HideSurebet = lazy(() => import("../../../Components/hide/HideSurebet"));
const Scanner = lazy(() => import("../../../Components/scanner/Scanner"));
const ScannerEspecial = lazy(() => import("../../../Components/scanner/ScannerFiltrado"));
// const accessToken = localStorage.getItem("accessToken");
// const socket = io("https://servicesurebets.com:3000/", {
//     query: {
//         variable: 2,
//         token: accessToken,
//     },
// });
//TOOLTIPS
const tooltip = (
    <Tooltip id="tooltip">
        <strong>Abrir en nueva ventana</strong>
    </Tooltip>
);
const tooltipF = (
    <Tooltip id="tooltipF">
        <strong>Solo ventanas flotantes</strong>
    </Tooltip>
);
const tooltipTC = (
    <Tooltip id="tooltipTC">
        <strong>Ver tipo de cambio</strong>
    </Tooltip>
);
function processSurebets(filtros, setDataSurebet, setDataSurebetPre, type) {

    return (data) => {

        //FILTRANDO POR DEPORTES
        const datafilterSport = data.filter((elem) =>
            filtros.deportes.find(({ value, checked }) => elem.sportname.toLowerCase() == String(value).toLowerCase() && checked)
        );

        //FILTRANDO POR CASAS
        const datafilter = datafilterSport.filter((elem) =>
            filtros.casas.find(({ value, checked }) => elem.bookmaker === value && checked)
        );

        // const datafilterPre = data.filter((elem) =>
        //     filtros.casas.find(({ value, checked }) => elem.bookmaker === value)
        // );

        //FILTRANDO POR INPUTS (PORCENTAJES)
        let perMin = filtros.inputs.percentMin != "" ? parseFloat(filtros.inputs.percentMin) : null;
        let perMax = filtros.inputs.percentMax != "" ? parseFloat(filtros.inputs.percentMax) : null;

        const dataFiltradazo = datafilter.filter((casas) => {
            let percent = parseFloat(casas.arbprofit);
            if (filtros.inputs.percentMint != "") {
                if (filtros.inputs.percentMax != "") {
                    if (perMin <= percent && percent <= perMax) {
                        return casas;
                    }
                } else {
                    if (perMin <= percent) {
                        return casas;
                    }
                }
            } else {
                if (filtros.inputs.percentMax != "") {
                    if (percent <= perMax) {
                        return casas;
                    }
                } else {
                    return casas;
                }
            }
        });

        //FILTRANDO POR INPUTS (ODDS)
        let oddMin = filtros.inputs.oddsMin != "" ? parseFloat(filtros.inputs.oddsMin) : null;
        let oddMax = filtros.inputs.oddsMax != "" ? parseFloat(filtros.inputs.oddsMax) : null;
        const filterSports = dataFiltradazo.filter((casas) => {
            let odd = parseFloat(casas.odd);
            if (filtros.inputs.oddsMin != "") {
                if (filtros.inputs.oddsMax != "") {
                    if (oddMin <= odd && odd <= oddMax) {
                        return casas;
                    }
                } else {
                    if (oddMin <= odd) {
                        return casas;
                    }
                }
            } else {
                if (filtros.inputs.oddsMax != "") {
                    if (odd <= oddMax) {
                        return casas;
                    }
                } else {
                    return casas;
                }
            }
        });

        filterSports.sort((s1, s2) => {
            return parseFloat(s2.arbprofit) - parseFloat(s1.arbprofit);
        });

        const datafilterPre = filterSports;

        //SCANNER ESPECIAL
        let filids = datafilterPre.map((ids) => ids.arbid);
        filids = Array.from(new Set(filids.filter((res) => filids.indexOf(res) !== filids.lastIndexOf(res))));
        const datos = [];
        setDataSurebet(datos);
        for (const ids of filids) {
            datos.push(filterSports.filter((datos) => datos.arbid === ids));
        }
        setDataSurebet(datos);

        let datosHide = [];
        if (type == 2) {
            datosHide = localStorage.getItem("hideEventsLive") ? JSON.parse(localStorage.getItem("hideEventsLive")) : [];
        } else {
            datosHide = localStorage.getItem("hideEventsPrematch") ? JSON.parse(localStorage.getItem("hideEventsPrematch")) : [];
        }

        if (datosHide.length > 0) {
            const arrayFiltrado = filtrarArrays(datos, datosHide);
            setDataSurebet(arrayFiltrado);
        }
    };
}

function filtrarArrays(arrayGeneral, arrayPequeno) {
    // Crear un conjunto de identificadores únicos para los elementos del array pequeño
    const identificadoresUnicos = new Set();

    // Recorrer el array pequeño y crear identificadores únicos basados en los campos especificados
    arrayPequeno.forEach(grupo => {
        grupo.forEach(item => {
            const identificador = `${item.typesurebet}_${item.sportname}_${item.bookmaker}_${item.eventname}_${item.leaguename}_${item.arboutcome}`;
            identificadoresUnicos.add(identificador);
        });
    });

    // Filtrar el array general
    return arrayGeneral.filter(grupo => {
        // Verificar si algún elemento del grupo coincide con los criterios
        const algunoCoincide = grupo.some(item => {
            const identificador = `${item.typesurebet}_${item.sportname}_${item.bookmaker}_${item.eventname}_${item.leaguename}_${item.arboutcome}`;
            return identificadoresUnicos.has(identificador);
        });

        // Si alguno coincide, eliminamos todo el grupo
        return !algunoCoincide;
    });
}

const Arbitraje = () => {
    //HOOKS
    const { user, filtrosLive, filtrosPrematch } = useUserContext();
    const [liveSure, setLiveSure] = useState([]);
    const [hideSure, setHideSure] = useState([]);
    const [dataSurebet, setDataSurebet] = useState([])
    const [dataSurebetPre, setDataSurebetPre] = useState([])
    const [dataFilter, setDataFilter] = useState([])
    const [type, setType] = useState(2);
    const [tc, setTc] = useState("normal");
    const [stake, setStake] = useState(user.stake);
    const [userTc, setUserTc] = useState(user.tipocambio);
    const [onlyF, setOnlyF] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [mood, setMood] = useState(false);
    const [disInput, setDisInput] = useState(true);
    const [trigger, setTrigger] = useState(0);
    const [showWindowPortal, setShowWindowPortal] = useState(false);
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const [pause, setPause] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    //METODOS Y MOUNTED

    useEffect(() => {
        // Marcar como primera carga cuando cambia el type
        setIsFirstLoad(true);
    }, [type]);

    useEffect(() => {
        const socket = io("https://bullbet.com.pe:4000/", {
            query: {
                variable: type,
                token: accessToken,
            },
        });

        socket.connect();

        const messageHandler = (data) => {
            if (isFirstLoad || !pause) {
                const reduceSurebets = processSurebets(
                    type == 2 ? JSON.parse(localStorage.getItem("filtrosLive")) : JSON.parse(localStorage.getItem("filtrosPrematch")),
                    setDataSurebet, setDataSurebetPre, type
                );
                reduceSurebets(data);

                if (isFirstLoad) {
                    setIsFirstLoad(false);
                }
            }
        };

        socket.on("server:message", messageHandler);

        if (pause && !isFirstLoad) {
            socket.off("server:message", messageHandler);
        }

        return () => {
            socket.off("server:message", messageHandler);
            socket.close();
            socket.disconnect();
        };
    }, [type, pause, isFirstLoad]);

    // useEffect(() => {
    //     const socket = io("https://bullbet.com.pe:4000/", {
    //         query: {
    //             variable: type,
    //             token: accessToken,
    //         },
    //     });
    //     socket.connect();
    //     socket.on("server:message", (data) => {

    //         const reduceSurebets = processSurebets(
    //             type == 2 ? JSON.parse(localStorage.getItem("filtrosLive")) : JSON.parse(localStorage.getItem("filtrosPrematch")),
    //             setDataSurebet, setDataSurebetPre, type
    //         );
    //         reduceSurebets(data);
    //     });

    //     return () => {
    //         socket.close();
    //         socket.disconnect();
    //     };
    // }, [type]);

    // const changeType = (tipo) => {
    //     setType(tipo)
    //     socket.io.opts.query.variable = tipo
    //     socket.close()
    //     socket.open()
    //     socket.io.on("reconnect_attempt", () => {
    //         socket.io.opts.query.x++;
    //       });
    // }
    const changeType = useCallback((tipo) => {
        setType(tipo);
    }, []);
    const showScanner = useCallback((tipo) => {
        setShowFilter(tipo);
    }, []);
    const onOptionChange = (e) => {
        setTc(e.target.checked);
    };
    const openWindow = () => {
        setTrigger((trigger) => trigger + 1);
        setShowWindowPortal(true);
    };
    const closeWindowPortal = () => {
        setShowWindowPortal(false);
    };
    const onlyFloat = (e) => {
        var checkBox = document.getElementById("chck1");
        if (e.target.checked) {
            setOnlyF(true);
            setDisInput(true);

            if (checkBox.checked == true) {
                checkBox.checked = false;
                checkBox.disabled = true;
            }
        } else {
            setOnlyF(false);
            if (liveSure.length > 0) {
                setDisInput(false);
                if (checkBox.checked == false) {
                    checkBox.checked = true;
                    checkBox.disabled = false;
                }
            }
        }
    };
    const setData = (data) => {
        // dataLive = data
        setLiveSure(data);
        if (data.length != 0) {
            if (onlyF) {
                openWindow();
            } else {
                // console.log('aqui')
                setDisInput(false);
                var checkBox = document.getElementById("chck1");
                if (checkBox.checked == false) {
                    checkBox.checked = true;
                }
            }
        }
    };

    const setHideDatos = (data) => {
        setHideSure(data);
        $("#modal_hidesurebet").modal("show");
    }

    const setDatos = (data) => {
        setDataFilter(data)
    }
    const ToogleMood = useCallback((val) => {
        setMood(val);
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", () => {
            closeWindowPortal();
        });
    }, []);
    const validation = async () => {
        const resp = await ValidateService.validateToken({ refreshToken: "Bearer " + refreshToken });
    };
    useEffect(() => {
        validation();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            await ValidateService.validateToken({ refreshToken: "Bearer " + refreshToken });
        }, 1000 * 30);
        return () => clearInterval(interval);
    }, []);
    return (
        <Main userStake={(datos) => setStake(datos)} userTC={(datos) => setUserTc(datos)}>
            <Suspense
                fallback={
                    <div className="py-6 d-flex justify-content-center align-items-center h-100">
                        <h1>Cargando...</h1>
                    </div>
                }
            >
                <div className="row w-100 mx-auto" style={{ marginTop: "-10px" }}>
                    <div className="col-md-6 col-sm-12 order-2 order-md-1 h-100">
                        <div className="card ">
                            <div className="d-flex flex-column flex-xl-row header_tabs_scanner rounded justify-content-between align-items-center">
                                <div className="d-flex w-100 w-xl-auto justify-content-start align-items-center">
                                    <button
                                        className={`btn btn-evento w-100 btn-sm ${type == 2 ? "tabs_active_sca" : "text-white"}`}
                                        onClick={() => changeType(2)}
                                    >
                                        LIVE
                                    </button>
                                    <button
                                        className={`btn btn-evento w-100 btn-sm ${type == 1 ? "tabs_active_sca" : "text-white"}`}
                                        onClick={() => changeType(1)}
                                    >
                                        PREMATCH
                                    </button>
                                </div>

                                <div className="px-4 d-flex">
                                    <button className="btn btn-sm btn-outline-warning p-0 px-2" onClick={() => setPause(!pause)}>
                                        {
                                            pause ? <FaPlay size={16} className="text-white"></FaPlay> : <FaPause size={16} className="text-white"></FaPause>
                                        }
                                    </button>
                                    <Filtro type={type == 2 ? "live" : "prematch"} />
                                    <button
                                        type="button"
                                        className="btn btn-filtro btn-sm py-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal1"
                                    >
                                        ESPECIALES
                                    </button>
                                    <div className="d-flex gap-2 justify-content-center align-items-center form-check form-switch form-check-custom form-check-primary form-check-solid">
                                        <input
                                            type="checkbox"
                                            className="form-check-input border_tc cursor-pointer"
                                            name="tipo_cambio"
                                            id="option-mood"
                                            value={mood}
                                            onChange={() => ToogleMood(!mood)}
                                        />
                                        <label htmlFor="option-mood" style={{ color: "white" }}>
                                            Normal/Resumido
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* {type} */}
                            <div className="">
                                <ScannerEspecial actSurebet={dataSurebet} type={type} setFilterData={(datos) => setDatos(datos)} setLiveSure={(liveSure) => setData(liveSure)} setHideSure={(datos) => setHideDatos(datos)} mood={mood} />
                                {/* <Scanner type={type} setLiveSure={(liveSure) => setData(liveSure)} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 order-1 order-md-2">
                        <div className="d-flex flex-column position-relative calculadora_content pb-3">
                            <div className="card mb-4 w-100">
                                <div className="w-100 d-flex flex-column">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="tabs">
                                                <div className="tab">
                                                    <input type="checkbox" id="chck1" disabled={disInput} />
                                                    <label className="tab-label p-2" htmlFor="chck1">
                                                        <div className="d-flex box_options ps-5 gap-2 justify-content-between w-100 pe-5">
                                                            <div className="d-flex gap-2">
                                                                <div className="d-flex align-items-center cursor-pointer me-2">
                                                                    <FaCalculator size={15}></FaCalculator>
                                                                </div>
                                                                <OverlayTrigger placement="top" overlay={tooltipTC}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center form-check form-switch form-check-custom form-check-primary form-check-solid">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input border_tc cursor-pointer"
                                                                            name="tipo_cambio"
                                                                            id="option-2"
                                                                            value={"tc"}
                                                                            onChange={onOptionChange}
                                                                        />
                                                                        <label htmlFor="option-2">TC</label>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="d-flex gap-2">
                                                                <div className="form-check form-switch form-check-custom form-check-success form-check-solid me-2">
                                                                    <OverlayTrigger placement="top" overlay={tooltipF}>
                                                                        <input
                                                                            className="form-check-input ms-1 cursor-pointer"
                                                                            type="checkbox"
                                                                            value="1"
                                                                            id="flexSwitchDefault"
                                                                            onChange={onlyFloat}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </div>

                                                                <OverlayTrigger placement="top" overlay={tooltip}>
                                                                    <button
                                                                        className=" btn btn-outline btn-outline-warning p-0 px-1"
                                                                        onClick={openWindow}
                                                                        aria-label="calculadora"
                                                                    >
                                                                        <FaWindowRestore></FaWindowRestore>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <div className="tab-content_cal card_contenido">
                                                        <Calculadora
                                                            actSurebet={liveSure}
                                                            tc={tc}
                                                            stake={stake}
                                                            userTc={userTc}
                                                            type={type}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="d-none d-md-block mb-4">
                                <div className=" px-6 bg_surebet_primary   fw-bolder fs-14 d-flex justify-content-between rounded-top">
                                    <div className="d-flex align-items-center justify-content-between w-100 py-2">
                                        <h6 className="fs-14 fw-bolder px-1 mb-0 text-white">MIDDLE</h6>
                                        <div className="d-flex gap-2 justify-content-center align-items-center form-check form-switch form-check-custom form-check-primary form-check-solid">
                                            <input
                                                type="checkbox"
                                                className="form-check-input border_tc cursor-pointer"
                                                name="tipo_filters"
                                                id="option-filters"
                                                value={showFilter}
                                                onChange={() => showScanner(!showFilter)}
                                            />
                                            <label htmlFor="option-filters" style={{ color: "white" }}>
                                                Ocultar/Ver
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <Scanner actSurebet={dataSurebetPre} dataFilter={dataFilter} type={type} setLiveSure={(liveSure) => setData(liveSure)} mood={mood} showFilter={showFilter} />
                                <ScannerEspecial type={type} setLiveSure={(liveSure) => setData(liveSure)} />
                            </div>*/}
                            {/*<Chat></Chat>*/}
                        </div>
                    </div>
                    <FiltroEspecial type={type == 2 ? "live" : "prematch"}></FiltroEspecial>
                    {/*showWindowPortal ? (
                        <Ventana
                            trigger={trigger}
                            closeWindowPortal={closeWindowPortal}
                            datos={liveSure}
                            type={"live"}
                            tc={tc}
                            stake={stake}
                            userTc={userTc}
                        ></Ventana>
                    ) : (
                        <></>
                    )}*/}
                    <HideSurebet surebet={hideSure} type={type}></HideSurebet>
                </div>
            </Suspense>
        </Main>
    );
};

export default Arbitraje;
