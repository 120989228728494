/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import "../auth.css";
import AuthService from "../../../api/services/authService";
import { useUserContext } from "../../../Providers/UserProvider";
import { FaEye, FaEyeSlash, FaEnvelope, FaLock } from "react-icons/fa";

const schema = yup.object({
    email: yup.string().email().required("Este campo es requerido"),
    password: yup.string().required("Este campo es requerido"),
});

const Login = ({ userUpLog }) => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [viewPass, setViewPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setFiltrosLive, setFiltrosPrematch } = useUserContext();
    const loginSend = async (datos) => {
        setLoading(true);
        const body = {
            correo: datos.email,
            contra: datos.password,
        };
        try {
            const resp = await AuthService.login(body);
            if (resp.status == true) {
                let config = localStorage.getItem("configExchange");
                if (config == "true" || config == true) {
                    let tipoCambio = JSON.parse(localStorage.getItem("tipoCambio"));
                    resp.data.usuario.tipocambio = tipoCambio;
                }else{
                    localStorage.setItem("configExchange", false);
                }
                window.localStorage.userCca = JSON.stringify(resp.data.usuario);
                resp.data.usuario.stake = 100;
                window.localStorage.accessToken =
                    resp.data.usuario.token.toString();
                window.localStorage.refreshToken =
                    resp.data.usuario.refreshToken.toString();
                let dtguardado = resp.data.filtroguardado;
                resp.data.filtro.map((e) => {
                    if (e.nombre === "live") {
                        let existe = dtguardado.find(
                            (x) => x.nombre === "live"
                        );
                        if (existe) {
                            localStorage.filtrosLive = JSON.stringify(
                                datosStorage(existe)
                            );
                            setFiltrosLive(existe);
                        } else {
                            localStorage.filtrosLive =
                                JSON.stringify(datosStorage(e));
                            setFiltrosLive(e);
                        }
                    } else {
                        let existe = dtguardado.find(
                            (x) => x.nombre === "prematch"
                        );
                        if (existe) {
                            localStorage.filtrosPrematch = JSON.stringify(
                                datosStorage(existe)
                            );
                            setFiltrosPrematch(existe);
                        } else {
                            localStorage.filtrosPrematch =
                                JSON.stringify(datosStorage(e));
                            setFiltrosPrematch(e);
                        }
                    }
                });
                localStorage.setItem("hideEventsLive", JSON.stringify([]));
                localStorage.setItem("hideEventsPrematch", JSON.stringify([]));
                userUpLog(resp.data.usuario);
                navigate("/perfil");
            } else {
                //console.log("entra aqui en error");
                setError("password", {
                    type: "noLogin",
                    message: "Correo o Contraseña incorrecta",
                });
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "Credenciales incorrectas",
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const datosStorage = (e) => {
        return {
            casas: e.casas,
            deportes: e.deportes,
            inputs: e.inputs,
            especiales: e.especiales,
        };
    };

    useEffect(() => {
        setTimeout(() => {
            if (document.getElementById("content_video") != null) {
                document
                    .getElementById("content_video")
                    .classList.add("efect-fade");
            }
        }, "4200");
        setTimeout(() => {
            if (document.getElementById("content_video") != null) {
                document
                    .getElementById("content_video")
                    .classList.add("no-show");
                document
                    .getElementById("content_video")
                    .classList.remove("efect-fade");
            }
            if (document.getElementById("login_form") != null) {
                document
                    .getElementById("login_form")
                    .classList.remove("no-show");
                document.getElementById("login_form").classList.add("d-flex");
            }
        }, "5200");
    }, []);
    return (
        <div className="d-flex flex-column flex-root h-100">
            <div className="row h-100 w-100 gx-0">
                {/* <div className="d-none d-lg-block col-lg-6 login_aside h-100"></div> */}
                <div className="col-12 h-100">
                    <div className="h-100 d-flex flex-center w-100 content_login position-relative">
                        <div
                            id="content_video"
                            className=" w-100 h-100 content_video"
                        >
                            <video
                                className="video"
                                src="/assets/bullbet/escanner_final1.mp4"
                                muted
                                autoPlay
                                playsInline
                                preload="auto"
                            ></video>
                            {/* <img src="/assets/bullbet/icon_logo.png" alt="" /> */}
                        </div>
                        <div
                            id="login_form"
                            className="login_form no-show w-100 w-lg-500px mh-lg-600px py-10 px-6 px-sm-15 py-sm-15  justify-content-start align-items-center mx-10 mx-lg-4 flex-column gap-4"
                        >
                            <div className="login_form_logo py-2 pb-lg-5">
                                <img alt="Logo" className="logo_login " />
                            </div>
                            {/* <div className="text-center">
                                <h2 className="text-dark mb-3">Inicio de Sesión</h2>
                            </div> */}
                            <form
                                className="form w-100 px-4 d-flex flex-column gap-1 py-2"
                                noValidate="noValidate"
                                id="kt_sign_in_form"
                                onSubmit={handleSubmit(loginSend)}
                            >
                                <div className="input-group mb-2 text-center text-sm-start">
                                    {/* <label className="form-label fs-6 fw-bold text-dark">Correo Electrónico</label> */}
                                    <span
                                        className="input-group-text bg-white border-login"
                                        id="correo_electronico"
                                    >
                                        <FaEnvelope></FaEnvelope>
                                    </span>
                                    <input
                                        className="form-control form-control-lg rounded-end bg-white border-login"
                                        style={{ color: "#5e6278" }}
                                        type="text"
                                        name="email"
                                        autoComplete="off"
                                        placeholder="Correo Electrónico"
                                        aria-describedby="correo_electronico"
                                        autoFocus
                                        tabIndex={1}
                                        // autoComplete="off"
                                        {...register("email")}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {errors.email?.type === "required" && (
                                            <div
                                                data-field="email"
                                                data-validator="regexp"
                                            >
                                                No ha ingresado un correo valido
                                            </div>
                                        )}
                                        {errors.email?.type === "email" && (
                                            <div
                                                data-field="email"
                                                data-validator="regexp"
                                            >
                                                Debe de ingresar un correo
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="fv-row mb-2">
                                    <div
                                        className="input-group mb-5"
                                        id="currentpassword"
                                    >
                                        <span className="input-group-text bg-white border-login">
                                            <FaLock></FaLock>
                                        </span>
                                        <input
                                            type={
                                                viewPass ? "text" : "password"
                                            }
                                            className="form-control form_pass form-control-lg bg-white border-login"
                                            placeholder="Contraseña"
                                            aria-label="Contraseña"
                                            name="password"
                                            aria-describedby="basic-addon2"
                                            id="password"
                                            {...register("password")}
                                            tabIndex={3}
                                        />
                                        <span
                                            className="input-group-text icon_pass border-login"
                                            id="basic-addon1"
                                        >
                                            {viewPass ? (
                                                <span
                                                    onClick={() =>
                                                        setViewPass(false)
                                                    }
                                                    className="cursor-pointer"
                                                >
                                                    <FaEye></FaEye>
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={() =>
                                                        setViewPass(true)
                                                    }
                                                    className="cursor-pointer"
                                                >
                                                    <FaEyeSlash></FaEyeSlash>
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {errors.password?.type ===
                                            "required" && (
                                                <div
                                                    data-field="password"
                                                    data-validator="notEmpty"
                                                >
                                                    La contraseña no es correcta
                                                </div>
                                            )}
                                        {errors.password?.type ===
                                            "noLogin" && (
                                                <div
                                                    data-field="password"
                                                    data-validator="notEmpty"
                                                >
                                                    {errors.password.message}
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="d-flex flex-center mb-2 text-center w-100">
                                    <Link
                                        to="/restaurar"
                                        className="link-primary fw-bold ms-1"
                                    >
                                        ¿Olvidaste tu contraseña?
                                    </Link>
                                </div>
                                <div className="text-center">
                                    <button
                                        disabled={loading}
                                        type="submit"
                                        id="kt_sign_in_submit"
                                        className="btn btn-lg btn-primary w-100 mb-5"
                                    >
                                        {loading ? (
                                            <span className="indicator-label">
                                                Cargando...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        ) : (
                                            <span className="indicator-label ">
                                                Ingresar
                                            </span>
                                        )}
                                    </button>
                                </div>
                                <div className="text-center mb-10">
                                    <div className="text-gray-400 fw-semibold fs-4">
                                        ¿Nuevo?
                                        <Link
                                            to="/registrarse"
                                            className="link-primary fw-bold ms-1"
                                        >
                                            Create una cuenta
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
